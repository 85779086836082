
import React from 'react'
import { ReportsSection } from '../../system/settings/pageData'
import Layout from '../../views/templates/adminLayout'
import ReportsView from '../../views/reports/index'

const PageInfo = {
    page: ReportsSection.section,
    icon: ReportsSection.icon,
}

const Reports = () => {
    return (
        <Layout PageInfo={PageInfo} minimumRole={ReportsSection.minimumRole}>
            <ReportsView />
        </Layout>
    )
}

export default Reports
